import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#182e25",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e8ebe9",
    },
  },
  typography: {
    fontFamily: "Raleway",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 700,
        },
      },
    },
  },
});

export default theme;