import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#182e25",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e8ebe9",
    },
  },
  typography: {
    fontFamily: "Raleway",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 700,
          "&.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ebe9",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ebe9",
            borderWidth: 2,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#e8ebe9",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "color": "#fff",
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        },
      },
    },
    MuiCheckbox: {
      //White outline when unchecked
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },
});

export default theme;